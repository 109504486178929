import { Link, useLocation } from "react-router-dom";
import "./NavBar.css";
import NavBarItem from "./NavBarItem";
import { useState } from "react";
import ProfilePopup from "../../pages/authorization_page/profile_popup/ProfilePopup";


const NavBar = () => {
  const location = useLocation();
  const [isProfilePopupOpen, setProfilePopupOpen] = useState(false) 
  return (
    <div className="nav-bar">
      {/* <Link to="/authorization"> */}
        <ProfilePopup 
        isOpen={isProfilePopupOpen}
        onClose={() => setProfilePopupOpen(false)} />

        <NavBarItem
          onClick={() => setProfilePopupOpen(true)}
          title={JSON.parse(localStorage.getItem('MY_USER') || '{}').email}
          // active={location.pathname === "/authorization"}
          iconDefault={
            (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12Z"
                  stroke="#5D5E64"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M8 10C8 12.2091 9.79086 14 12 14C14.2091 14 16 12.2091 16 10C16 7.79086 14.2091 6 12 6C9.79086 6 8 7.79086 8 10Z"
                  stroke="#5D5E64"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M6.35034 19.5C7.65453 17.9502 9.60162 17 11.7202 17H12.2799C14.3985 17 16.3456 17.9502 17.6498 19.5"
                  stroke="#5D5E64"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) as JSX.Element
          }
          iconActiveMobile={
            (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M6.19898 21.052C7.87246 22.1267 9.86344 22.75 12 22.75C14.1366 22.75 16.1275 22.1267 17.801 21.052C16.726 19.0482 14.6201 17.75 12.2798 17.75H11.7202C9.37994 17.75 7.27405 19.0482 6.19898 21.052Z"
                  fill="#00DF5F"
                />
                <path
                  d="M12 6.75C10.2051 6.75 8.75 8.20507 8.75 10C8.75 11.7949 10.2051 13.25 12 13.25C13.7949 13.25 15.25 11.7949 15.25 10C15.25 8.20507 13.7949 6.75 12 6.75Z"
                  fill="#00DF5F"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.98597 20.1468C2.69829 18.1754 1.25 15.2568 1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 15.2568 21.3017 18.1754 19.014 20.1468C17.6517 17.7748 15.1034 16.25 12.2798 16.25H11.7202C8.89663 16.25 6.34834 17.7748 4.98597 20.1468ZM7.25 10C7.25 7.37665 9.37665 5.25 12 5.25C14.6234 5.25 16.75 7.37665 16.75 10C16.75 12.6234 14.6234 14.75 12 14.75C9.37665 14.75 7.25 12.6234 7.25 10Z"
                  fill="#00DF5F"
                />
              </svg>
            ) as JSX.Element
          }
          iconActiveDesktop={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M6.19898 21.052C7.87246 22.1267 9.86344 22.75 12 22.75C14.1366 22.75 16.1275 22.1267 17.801 21.052C16.726 19.0482 14.6201 17.75 12.2798 17.75H11.7202C9.37994 17.75 7.27405 19.0482 6.19898 21.052Z"
                fill="#2C2D2E"
              />
              <path
                d="M12 6.75C10.2051 6.75 8.75 8.20507 8.75 10C8.75 11.7949 10.2051 13.25 12 13.25C13.7949 13.25 15.25 11.7949 15.25 10C15.25 8.20507 13.7949 6.75 12 6.75Z"
                fill="#2C2D2E"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.98597 20.1468C2.69829 18.1754 1.25 15.2568 1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 15.2568 21.3017 18.1754 19.014 20.1468C17.6517 17.7748 15.1034 16.25 12.2798 16.25H11.7202C8.89663 16.25 6.34834 17.7748 4.98597 20.1468ZM7.25 10C7.25 7.37665 9.37665 5.25 12 5.25C14.6234 5.25 16.75 7.37665 16.75 10C16.75 12.6234 14.6234 14.75 12 14.75C9.37665 14.75 7.25 12.6234 7.25 10Z"
                fill="#2C2D2E"
              />
            </svg>
          }
        />
      {/* </Link> */}

      <Link to="/orders">
        <NavBarItem
          title="Заказы"
          active={location.pathname === "/orders"}
          iconDefault={
            (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M8 4.17871C7.6869 4.24093 7.4095 4.32476 7.15057 4.43773C5.93661 4.96738 4.96769 5.93629 4.43805 7.15025C4 8.15427 4 9.43607 4 11.9997V13.9997C4 16.5633 4 17.8451 4.43805 18.8491C4.96769 20.0631 5.93661 21.032 7.15057 21.5616C8.15458 21.9997 9.43639 21.9997 12 21.9997C14.5636 21.9997 15.8454 21.9997 16.8494 21.5616C18.0634 21.032 19.0323 20.0631 19.562 18.8491C20 17.8451 20 16.5633 20 13.9997V11.9997C20 9.43607 20 8.15427 19.562 7.15025C19.0323 5.93629 18.0634 4.96738 16.8494 4.43773C16.5905 4.32476 16.3131 4.24093 16 4.17871"
                  stroke="#5D5E64"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M8 4C8 2.89543 8.89543 2 10 2H14C15.1046 2 16 2.89543 16 4C16 5.10457 15.1046 6 14 6H10C8.89543 6 8 5.10457 8 4Z"
                  stroke="#5D5E64"
                  strokeWidth="2"
                />
                <path
                  d="M7.25 12L7.86612 12.6161C8.35427 13.1043 9.14573 13.1043 9.63388 12.6161L10.75 11.5"
                  stroke="#5D5E64"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M7.25 17L7.86612 17.6161C8.35427 18.1043 9.14573 18.1043 9.63388 17.6161L10.75 16.5"
                  stroke="#5D5E64"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M13.75 12H16.75"
                  stroke="#5D5E64"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M13.75 17H16.75"
                  stroke="#5D5E64"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            ) as JSX.Element
          }
          iconActiveMobile={
            (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.01281 4.19824C6.00433 4.29764 6 4.39823 6 4.49983C6 6.43282 7.567 7.99983 9.5 7.99983H14.5C16.433 7.99983 18 6.43282 18 4.49983C18 4.39823 17.9957 4.29764 17.9872 4.19824C19.0665 4.88992 19.9061 5.91617 20.3654 7.13658C20.75 8.15835 20.75 9.43892 20.75 12.0001V14.0001C20.75 16.5612 20.75 17.8418 20.3654 18.8636C19.7568 20.4807 18.4806 21.7569 16.8635 22.3655C15.8417 22.7501 14.5612 22.7501 12 22.7501C9.43884 22.7501 8.15826 22.7501 7.1365 22.3655C5.51941 21.7569 4.24319 20.4807 3.63456 18.8636C3.25 17.8418 3.25 16.5612 3.25 14.0001V12.0001C3.25 9.43892 3.25 8.15835 3.63456 7.13658C4.09389 5.91617 4.93345 4.88992 6.01281 4.19824ZM13 12C13 11.5858 13.3358 11.25 13.75 11.25H16.75C17.1642 11.25 17.5 11.5858 17.5 12C17.5 12.4142 17.1642 12.75 16.75 12.75H13.75C13.3358 12.75 13 12.4142 13 12ZM13.75 16.25C13.3358 16.25 13 16.5858 13 17C13 17.4142 13.3358 17.75 13.75 17.75H16.75C17.1642 17.75 17.5 17.4142 17.5 17C17.5 16.5858 17.1642 16.25 16.75 16.25H13.75ZM11.2803 10.9697C11.5732 11.2626 11.5732 11.7374 11.2803 12.0303L10.1642 13.1464C9.38317 13.9275 8.11683 13.9275 7.33579 13.1464L6.71967 12.5303C6.42678 12.2374 6.42678 11.7626 6.71967 11.4697C7.01256 11.1768 7.48744 11.1768 7.78033 11.4697L8.39645 12.0858C8.59171 12.281 8.90829 12.281 9.10355 12.0858L10.2197 10.9697C10.5126 10.6768 10.9874 10.6768 11.2803 10.9697ZM11.2803 17.0303C11.5732 16.7374 11.5732 16.2626 11.2803 15.9697C10.9874 15.6768 10.5126 15.6768 10.2197 15.9697L9.10355 17.0858C8.90829 17.281 8.59171 17.281 8.39645 17.0858L7.78033 16.4697C7.48744 16.1768 7.01256 16.1768 6.71967 16.4697C6.42678 16.7626 6.42678 17.2374 6.71967 17.5303L7.33579 18.1464C8.11683 18.9275 9.38317 18.9275 10.1642 18.1464L11.2803 17.0303Z"
                  fill="#00DF5F"
                />
                <path
                  d="M7.25 4C7.25 2.48122 8.48122 1.25 10 1.25H14C15.5188 1.25 16.75 2.48122 16.75 4C16.75 5.51878 15.5188 6.75 14 6.75H10C8.48122 6.75 7.25 5.51878 7.25 4Z"
                  fill="#00DF5F"
                />
              </svg>
            ) as JSX.Element
          }
          iconActiveDesktop={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clipPath="url(#clip0_2926_15070)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.01281 4.19824C6.00433 4.29764 6 4.39823 6 4.49983C6 6.43282 7.567 7.99983 9.5 7.99983H14.5C16.433 7.99983 18 6.43282 18 4.49983C18 4.39823 17.9957 4.29764 17.9872 4.19824C19.0665 4.88992 19.9061 5.91617 20.3654 7.13658C20.75 8.15835 20.75 9.43892 20.75 12.0001V14.0001C20.75 16.5612 20.75 17.8418 20.3654 18.8636C19.7568 20.4807 18.4806 21.7569 16.8635 22.3655C15.8417 22.7501 14.5612 22.7501 12 22.7501C9.43884 22.7501 8.15826 22.7501 7.1365 22.3655C5.51941 21.7569 4.24319 20.4807 3.63456 18.8636C3.25 17.8418 3.25 16.5612 3.25 14.0001V12.0001C3.25 9.43892 3.25 8.15835 3.63456 7.13658C4.09389 5.91617 4.93345 4.88992 6.01281 4.19824ZM13 12C13 11.5858 13.3358 11.25 13.75 11.25H16.75C17.1642 11.25 17.5 11.5858 17.5 12C17.5 12.4142 17.1642 12.75 16.75 12.75H13.75C13.3358 12.75 13 12.4142 13 12ZM13.75 16.25C13.3358 16.25 13 16.5858 13 17C13 17.4142 13.3358 17.75 13.75 17.75H16.75C17.1642 17.75 17.5 17.4142 17.5 17C17.5 16.5858 17.1642 16.25 16.75 16.25H13.75ZM11.2803 10.9697C11.5732 11.2626 11.5732 11.7374 11.2803 12.0303L10.1642 13.1464C9.38317 13.9275 8.11683 13.9275 7.33579 13.1464L6.71967 12.5303C6.42678 12.2374 6.42678 11.7626 6.71967 11.4697C7.01256 11.1768 7.48744 11.1768 7.78033 11.4697L8.39645 12.0858C8.59171 12.281 8.90829 12.281 9.10355 12.0858L10.2197 10.9697C10.5126 10.6768 10.9874 10.6768 11.2803 10.9697ZM11.2803 17.0303C11.5732 16.7374 11.5732 16.2626 11.2803 15.9697C10.9874 15.6768 10.5126 15.6768 10.2197 15.9697L9.10355 17.0858C8.90829 17.281 8.59171 17.281 8.39645 17.0858L7.78033 16.4697C7.48744 16.1768 7.01256 16.1768 6.71967 16.4697C6.42678 16.7626 6.42678 17.2374 6.71967 17.5303L7.33579 18.1464C8.11683 18.9275 9.38317 18.9275 10.1642 18.1464L11.2803 17.0303Z"
                  fill="#2C2D2E"
                />
                <path
                  d="M7.25 4C7.25 2.48122 8.48122 1.25 10 1.25H14C15.5188 1.25 16.75 2.48122 16.75 4C16.75 5.51878 15.5188 6.75 14 6.75H10C8.48122 6.75 7.25 5.51878 7.25 4Z"
                  fill="#2C2D2E"
                />
              </g>
              <defs>
                <clipPath id="clip0_2926_15070">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          }
        />
      </Link>

      <Link to="/menu">
        <NavBarItem
          title="Меню"
          active={location.pathname === "/menu"}
          iconDefault={
            (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M22 12C22 15.2636 22 16.8953 21.4273 18.1677C20.7755 19.616 19.616 20.7755 18.1677 21.4274C16.8953 22 15.2635 22 12 22C8.73646 22 7.10469 22 5.83232 21.4274C4.38401 20.7755 3.22448 19.616 2.57265 18.1677C2 16.8953 2 15.2636 2 12C2 8.73648 2 7.10471 2.57265 5.83233C3.22448 4.38403 4.38401 3.22449 5.83232 2.57267C6.96755 2.06174 8.38888 2.00667 11 2.00073"
                  stroke="#5D5E64"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M15.0252 3.63499L9.8416 8.81862C9.27537 9.38485 8.99225 9.66797 8.75466 9.97685C8.21823 10.6742 7.84174 11.481 7.65185 12.3401C7.56775 12.7206 7.53263 13.1195 7.4624 13.9172C7.40911 14.5224 7.38247 14.8249 7.44015 15.0638C7.57221 15.6107 7.99924 16.0378 8.54616 16.1698C8.78502 16.2275 9.08762 16.2009 9.69281 16.1476C10.4905 16.0773 10.8893 16.0422 11.2699 15.9581C12.1289 15.7682 12.9357 15.3917 13.6331 14.8553C13.942 14.6177 14.2251 14.3346 14.7913 13.7684L19.975 8.58474C21.3418 7.2179 21.3418 5.00182 19.975 3.63499C18.6081 2.26815 16.3921 2.26816 15.0252 3.63499Z"
                  stroke="#5D5E64"
                  strokeWidth="2"
                />
                <path
                  d="M14 5L18.6508 9.65079"
                  stroke="#5D5E64"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) as JSX.Element
          }
          iconActiveMobile={
            (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M22 12C22 15.2636 22 16.8953 21.4273 18.1677C20.7755 19.616 19.616 20.7755 18.1677 21.4274C16.8953 22 15.2635 22 12 22C8.73646 22 7.10469 22 5.83232 21.4274C4.38401 20.7755 3.22448 19.616 2.57265 18.1677C2 16.8953 2 15.2636 2 12C2 8.73648 2 7.10471 2.57265 5.83233C3.22448 4.38403 4.38401 3.22449 5.83232 2.57267C6.96755 2.06174 8.38888 2.00667 11 2.00073"
                  stroke="#00DF5F"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M13.8303 3.76947L14.494 3.10576C16.1537 1.44603 18.8447 1.44603 20.5044 3.10576C22.1641 4.76549 22.1641 7.45644 20.5044 9.11616L19.8407 9.77988L13.8303 3.76947Z"
                  fill="#00DF5F"
                />
                <path
                  d="M12.7696 4.83013L9.15437 8.44538C8.62871 8.97104 8.36588 9.23387 8.14161 9.51401C7.3717 10.4757 6.89093 11.6364 6.75532 12.8608C6.71582 13.2175 6.71582 13.5892 6.71582 14.3325C6.71582 14.8546 6.71582 15.1159 6.76429 15.332C6.9336 16.087 7.52319 16.6766 8.27818 16.8459C8.4943 16.8943 8.75537 16.8943 9.27749 16.8943C10.0209 16.8943 10.3927 16.8943 10.7494 16.8548C11.9738 16.7192 13.1345 16.2385 14.0962 15.4685C14.3763 15.2443 14.6391 14.9814 15.1648 14.4558L18.78 10.8405L12.7696 4.83013Z"
                  fill="#00DF5F"
                />
              </svg>
            ) as JSX.Element
          }
          iconActiveDesktop={
            (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clipPath="url(#clip0_2926_20305)">
                  <path
                    d="M22 12C22 15.2636 22 16.8953 21.4273 18.1677C20.7755 19.616 19.616 20.7755 18.1677 21.4274C16.8953 22 15.2635 22 12 22C8.73646 22 7.10469 22 5.83232 21.4274C4.38401 20.7755 3.22448 19.616 2.57265 18.1677C2 16.8953 2 15.2636 2 12C2 8.73648 2 7.10471 2.57265 5.83233C3.22448 4.38403 4.38401 3.22449 5.83232 2.57267C6.96755 2.06174 8.38888 2.00667 11 2.00073"
                    stroke="#2C2D2E"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M13.8303 3.7695L14.494 3.10579C16.1537 1.44606 18.8447 1.44606 20.5044 3.10579C22.1641 4.76552 22.1641 7.45647 20.5044 9.1162L19.8407 9.77991L13.8303 3.7695Z"
                    fill="#2C2D2E"
                  />
                  <path
                    d="M12.7696 4.83016L9.15437 8.44541C8.62871 8.97107 8.36588 9.2339 8.14161 9.51404C7.3717 10.4757 6.89093 11.6364 6.75532 12.8608C6.71582 13.2175 6.71582 13.5892 6.71582 14.3326C6.71582 14.8546 6.71582 15.1159 6.76429 15.332C6.9336 16.087 7.52319 16.6766 8.27818 16.8459C8.4943 16.8944 8.75537 16.8944 9.27749 16.8944C10.0209 16.8944 10.3927 16.8944 10.7494 16.8549C11.9738 16.7193 13.1345 16.2385 14.0962 15.4686C14.3763 15.2443 14.6391 14.9815 15.1648 14.4558L18.78 10.8406L12.7696 4.83016Z"
                    fill="#2C2D2E"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2926_20305">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            ) as JSX.Element
          }
        />
      </Link>

      <Link to="/restaurant-page">
        <NavBarItem
          title="Страница заведения"
          active={location.pathname === "/restaurant-page"}
          iconDefault={
            (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12Z"
                  stroke="#5D5E64"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M12 15.5L12 12.5"
                  stroke="#5D5E64"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M12 8.5L12 8.499"
                  stroke="#2C2D2E"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) as JSX.Element
          }
          iconActiveMobile={
            (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25C6.06294 1.25 1.25 6.06294 1.25 12ZM12 11.75C12.4142 11.75 12.75 12.0858 12.75 12.5V15.5C12.75 15.9142 12.4142 16.25 12 16.25C11.5858 16.25 11.25 15.9142 11.25 15.5V12.5C11.25 12.0858 11.5858 11.75 12 11.75ZM11 8.5C11 9.05228 11.4477 9.5 12 9.5C12.5523 9.5 13 9.05228 13 8.5C13 7.94772 12.5523 7.499 12 7.499C11.4477 7.499 11 7.94772 11 8.5Z"
                  fill="#00DF5F"
                />
              </svg>
            ) as JSX.Element
          }
          iconActiveDesktop={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clipPath="url(#clip0_2926_21639)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25C6.06294 1.25 1.25 6.06294 1.25 12ZM12 11.75C12.4142 11.75 12.75 12.0858 12.75 12.5V15.5C12.75 15.9142 12.4142 16.25 12 16.25C11.5858 16.25 11.25 15.9142 11.25 15.5V12.5C11.25 12.0858 11.5858 11.75 12 11.75ZM11 8.5C11 9.05228 11.4477 9.5 12 9.5C12.5523 9.5 13 9.05228 13 8.5C13 7.94772 12.5523 7.499 12 7.499C11.4477 7.499 11 7.94772 11 8.5Z"
                  fill="#2C2D2E"
                />
              </g>
              <defs>
                <clipPath id="clip0_2926_21639">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          }
        />
      </Link>

      <Link to="/positions">
        <NavBarItem
          title="Позиции на главной"
          active={location.pathname === "/positions"}
          iconDefault={
            (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M2 17C2 15.6036 2 14.9053 2.19641 14.344C2.5482 13.3387 3.33866 12.5482 4.34402 12.1964C4.90533 12 5.60355 12 7 12C8.39645 12 9.09467 12 9.65598 12.1964C10.6613 12.5482 11.4518 13.3387 11.8036 14.344C12 14.9053 12 15.6036 12 17C12 18.3964 12 19.0947 11.8036 19.656C11.4518 20.6613 10.6613 21.4518 9.65598 21.8036C9.09467 22 8.39645 22 7 22C5.60355 22 4.90533 22 4.34402 21.8036C3.33866 21.4518 2.5482 20.6613 2.19641 19.656C2 19.0947 2 18.3964 2 17Z"
                  stroke="#5D5E64"
                  strokeWidth="2"
                />
                <path
                  d="M5 19H6"
                  stroke="#5D5E64"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M12 17C12 15.6036 12 14.9053 12.1964 14.344C12.5482 13.3387 13.3387 12.5482 14.344 12.1964C14.9053 12 15.6036 12 17 12C18.3964 12 19.0947 12 19.656 12.1964C20.6613 12.5482 21.4518 13.3387 21.8036 14.344C22 14.9053 22 15.6036 22 17C22 18.3964 22 19.0947 21.8036 19.656C21.4518 20.6613 20.6613 21.4518 19.656 21.8036C19.0947 22 18.3964 22 17 22C15.6036 22 14.9053 22 14.344 21.8036C13.3387 21.4518 12.5482 20.6613 12.1964 19.656C12 19.0947 12 18.3964 12 17Z"
                  stroke="#5D5E64"
                  strokeWidth="2"
                />
                <path
                  d="M7 7C7 5.60355 7 4.90533 7.19641 4.34402C7.5482 3.33866 8.33866 2.5482 9.34402 2.19641C9.90533 2 10.6036 2 12 2C13.3964 2 14.0947 2 14.656 2.19641C15.6613 2.5482 16.4518 3.33866 16.8036 4.34402C17 4.90533 17 5.60355 17 7C17 8.39645 17 9.09467 16.8036 9.65598C16.4518 10.6613 15.6613 11.4518 14.656 11.8036C14.0947 12 13.3964 12 12 12C10.6036 12 9.90533 12 9.34402 11.8036C8.33866 11.4518 7.5482 10.6613 7.19641 9.65598C7 9.09467 7 8.39645 7 7Z"
                  stroke="#5D5E64"
                  strokeWidth="2"
                />
                <path
                  d="M10 9H11"
                  stroke="#5D5E64"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M15 19H16"
                  stroke="#5D5E64"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            ) as JSX.Element
          }
          iconActiveMobile={
            (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.41224 14.3369C1.25 14.9072 1.25 15.6048 1.25 17C1.25 18.3952 1.25 19.0928 1.41224 19.6631C1.81499 21.0786 2.92141 22.185 4.33693 22.5878C4.90715 22.75 5.60477 22.75 7 22.75C8.39523 22.75 9.09285 22.75 9.66307 22.5878C11.0786 22.185 12.185 21.0786 12.5878 19.6631C12.75 19.0928 12.75 18.3952 12.75 17C12.75 15.6048 12.75 14.9072 12.5878 14.3369C12.185 12.9214 11.0786 11.815 9.66307 11.4122C9.09285 11.25 8.39523 11.25 7 11.25C5.60477 11.25 4.90715 11.25 4.33693 11.4122C2.92141 11.815 1.81499 12.9214 1.41224 14.3369ZM5 18.25C4.58579 18.25 4.25 18.5858 4.25 19C4.25 19.4142 4.58579 19.75 5 19.75H6C6.41421 19.75 6.75 19.4142 6.75 19C6.75 18.5858 6.41421 18.25 6 18.25H5Z"
                  fill="#00DF5F"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.41224 4.33693C6.25 4.90715 6.25 5.60477 6.25 7C6.25 8.39523 6.25 9.09285 6.41224 9.66307C6.81499 11.0786 7.92141 12.185 9.33693 12.5878C9.90715 12.75 10.6048 12.75 12 12.75C13.3952 12.75 14.0928 12.75 14.6631 12.5878C16.0786 12.185 17.185 11.0786 17.5878 9.66307C17.75 9.09285 17.75 8.39523 17.75 7C17.75 5.60477 17.75 4.90715 17.5878 4.33693C17.185 2.92141 16.0786 1.81499 14.6631 1.41224C14.0928 1.25 13.3952 1.25 12 1.25C10.6048 1.25 9.90715 1.25 9.33693 1.41224C7.92141 1.81499 6.81499 2.92141 6.41224 4.33693ZM10 8.25C9.58579 8.25 9.25 8.58579 9.25 9C9.25 9.41421 9.58579 9.75 10 9.75H11C11.4142 9.75 11.75 9.41421 11.75 9C11.75 8.58579 11.4142 8.25 11 8.25H10Z"
                  fill="#00DF5F"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.4122 14.3369C11.25 14.9072 11.25 15.6048 11.25 17C11.25 18.3952 11.25 19.0928 11.4122 19.6631C11.815 21.0786 12.9214 22.185 14.3369 22.5878C14.9072 22.75 15.6048 22.75 17 22.75C18.3952 22.75 19.0928 22.75 19.6631 22.5878C21.0786 22.185 22.185 21.0786 22.5878 19.6631C22.75 19.0928 22.75 18.3952 22.75 17C22.75 15.6048 22.75 14.9072 22.5878 14.3369C22.185 12.9214 21.0786 11.815 19.6631 11.4122C19.0928 11.25 18.3952 11.25 17 11.25C15.6048 11.25 14.9072 11.25 14.3369 11.4122C12.9214 11.815 11.815 12.9214 11.4122 14.3369ZM15 18.25C14.5858 18.25 14.25 18.5858 14.25 19C14.25 19.4142 14.5858 19.75 15 19.75H16C16.4142 19.75 16.75 19.4142 16.75 19C16.75 18.5858 16.4142 18.25 16 18.25H15Z"
                  fill="#00DF5F"
                />
              </svg>
            ) as JSX.Element
          }
          iconActiveDesktop={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clipPath="url(#clip0_2926_14304)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.41224 14.3369C1.25 14.9072 1.25 15.6048 1.25 17C1.25 18.3952 1.25 19.0928 1.41224 19.6631C1.81499 21.0786 2.92141 22.185 4.33693 22.5878C4.90715 22.75 5.60477 22.75 7 22.75C8.39523 22.75 9.09285 22.75 9.66307 22.5878C11.0786 22.185 12.185 21.0786 12.5878 19.6631C12.75 19.0928 12.75 18.3952 12.75 17C12.75 15.6048 12.75 14.9072 12.5878 14.3369C12.185 12.9214 11.0786 11.815 9.66307 11.4122C9.09285 11.25 8.39523 11.25 7 11.25C5.60477 11.25 4.90715 11.25 4.33693 11.4122C2.92141 11.815 1.81499 12.9214 1.41224 14.3369ZM5 18.25C4.58579 18.25 4.25 18.5858 4.25 19C4.25 19.4142 4.58579 19.75 5 19.75H6C6.41421 19.75 6.75 19.4142 6.75 19C6.75 18.5858 6.41421 18.25 6 18.25H5Z"
                  fill="#2C2D2E"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.41224 4.33693C6.25 4.90715 6.25 5.60477 6.25 7C6.25 8.39523 6.25 9.09285 6.41224 9.66307C6.81499 11.0786 7.92141 12.185 9.33693 12.5878C9.90715 12.75 10.6048 12.75 12 12.75C13.3952 12.75 14.0928 12.75 14.6631 12.5878C16.0786 12.185 17.185 11.0786 17.5878 9.66307C17.75 9.09285 17.75 8.39523 17.75 7C17.75 5.60477 17.75 4.90715 17.5878 4.33693C17.185 2.92141 16.0786 1.81499 14.6631 1.41224C14.0928 1.25 13.3952 1.25 12 1.25C10.6048 1.25 9.90715 1.25 9.33693 1.41224C7.92141 1.81499 6.81499 2.92141 6.41224 4.33693ZM10 8.25C9.58579 8.25 9.25 8.58579 9.25 9C9.25 9.41421 9.58579 9.75 10 9.75H11C11.4142 9.75 11.75 9.41421 11.75 9C11.75 8.58579 11.4142 8.25 11 8.25H10Z"
                  fill="#2C2D2E"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.4122 14.3369C11.25 14.9072 11.25 15.6048 11.25 17C11.25 18.3952 11.25 19.0928 11.4122 19.6631C11.815 21.0786 12.9214 22.185 14.3369 22.5878C14.9072 22.75 15.6048 22.75 17 22.75C18.3952 22.75 19.0928 22.75 19.6631 22.5878C21.0786 22.185 22.185 21.0786 22.5878 19.6631C22.75 19.0928 22.75 18.3952 22.75 17C22.75 15.6048 22.75 14.9072 22.5878 14.3369C22.185 12.9214 21.0786 11.815 19.6631 11.4122C19.0928 11.25 18.3952 11.25 17 11.25C15.6048 11.25 14.9072 11.25 14.3369 11.4122C12.9214 11.815 11.815 12.9214 11.4122 14.3369ZM15 18.25C14.5858 18.25 14.25 18.5858 14.25 19C14.25 19.4142 14.5858 19.75 15 19.75H16C16.4142 19.75 16.75 19.4142 16.75 19C16.75 18.5858 16.4142 18.25 16 18.25H15Z"
                  fill="#2C2D2E"
                />
              </g>
              <defs>
                <clipPath id="clip0_2926_14304">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          }
        />
      </Link>
    </div>
  );
};

export default NavBar;
