import { DietTag } from "../../../assets/constants/content_types/DietTag"
import { FoodPositionInOrderInfo, FoodPositionInfo, VariationType } from "../../../assets/constants/content_types/FoodInfo"
import { MenuSectionTag } from "../../../assets/constants/content_types/MenuSectionTag"

export const mockFood1: FoodPositionInfo[] = [
  {
    id: '1',
    restaurantId: '1',
    name: 'Картофель фри',
    image: 'https://i.imgur.com/6dtZI35.jpg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.SALADS, MenuSectionTag.RECOMMENDED],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 700,
        weight: 150,
        ingredients: 'картофель фри, трюфельное масло',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.HEALTHY_STOMACH, DietTag.DIABETIC],
        restrictedAllergyTags: [],
      },
      {
        variationId: '2',
        variationName: 'С сыром',
        variationType: VariationType.CUSTOM,
        price: 800,
        weight: 170,
        ingredients: 'картофель фри, трюфельное масло, сыр',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 222,
        fats: 333,
        carbohydrates: 444,
        calories: 555,
        allowedDietsTags: [DietTag.HEALTHY_HEART, DietTag.VEGAN],
        restrictedAllergyTags: [],
      }
    ],
  },
  {
    id: '2',
    restaurantId: '1',
    name: 'Авторский салат с авокадо и гранатом',
    image: 'https://i.imgur.com/RWer6Wh.jpg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.SALADS, MenuSectionTag.SEASON],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 600,
        weight: 150,
        ingredients: 'салатный лист, авокадо, гранат, гренки, петрушка, лук, капуста',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.VEGAN, DietTag.VEGETARIAN],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '3',
    restaurantId: '1',
    name: 'Тыквенный суп-пюре',
    image: 'https://i.imgur.com/nLjUw2s.jpg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.SALADS, MenuSectionTag.SEASON],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 444,
        weight: 150,
        ingredients: 'суп',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.SPORT, DietTag.HEALTHY_STOMACH],
        restrictedAllergyTags: [],
      },
      {
        variationId: '2',
        variationName: 'С семечками',
        variationType: VariationType.CUSTOM,
        price: 586,
        weight: 170,
        ingredients: 'суп, семечки',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 222,
        fats: 333,
        carbohydrates: 444,
        calories: 555,
        allowedDietsTags: [DietTag.DIABETIC, DietTag.LESS_PROTEIN],
        restrictedAllergyTags: [],
      }
    ],
  },
  {
    id: '4',
    restaurantId: '1',
    name: 'Чай',
    image: 'https://i.imgur.com/uuiNeFb.jpg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.DRINKS],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 100,
        weight: 150,
        ingredients: 'чай',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.VEGAN, DietTag.VEGETARIAN],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '5',
    restaurantId: '1',
    name: 'Кофе',
    image: 'https://i.imgur.com/pt5XXEj.jpg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.DRINKS, MenuSectionTag.RECOMMENDED],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 200,
        weight: 200,
        ingredients: 'кофе',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.VEGAN, DietTag.VEGETARIAN],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '6',
    restaurantId: '1',
    name: 'Лимонад',
    image: 'https://i.imgur.com/tX5OI7y.jpg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.DRINKS, MenuSectionTag.SEASON],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 250,
        weight: 200,
        ingredients: 'лимонад',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.VEGAN, DietTag.VEGETARIAN],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '7',
    restaurantId: '1',
    name: 'Пирожок с вишней',
    image: 'https://i.imgur.com/FUibMv0.jpg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.DESERTS],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 210,
        weight: 200,
        ingredients: 'вишня, тесто',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.HEALTHY_STOMACH],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '8',
    restaurantId: '1',
    name: 'Пирог с яблоком и корицей',
    image: 'https://i.imgur.com/FUibMv0.jpg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.DESERTS, MenuSectionTag.RECOMMENDED],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 400,
        weight: 200,
        ingredients: 'яблоко, корица, тесто',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.HEALTHY_STOMACH],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '9',
    restaurantId: '1',
    name: 'Торт тирамису',
    image: 'https://i.imgur.com/FUibMv0.jpg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.DESERTS, MenuSectionTag.RECOMMENDED],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 700,
        weight: 200,
        ingredients: 'че там в составе я не знаю',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.HEALTHY_STOMACH],
        restrictedAllergyTags: [],
      },
    ],
  },
]

export const mockFood2: FoodPositionInfo[] = [{
  id: '10',
  restaurantId: '2',
  name: 'Салат с помидорами и куриной грудкой',
  image: 'https://i.imgur.com/lv62Sr6.jpg',
  isOnStartPage: true,
  menuSectionTags: [MenuSectionTag.SALADS],
  foodVariations: [
    {
      variationId: '1',
      variationName: 'Стандартный',
      variationType: VariationType.STANDARD,
      price: 700,
      weight: 150,
      ingredients: 'салат, помидор, куриная грудка',
      CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
      proteins: 666,
      fats: 777,
      carbohydrates: 888,
      calories: 999,
      allowedDietsTags: [DietTag.HEALTHY_STOMACH, DietTag.DIABETIC],
      restrictedAllergyTags: [],
    },
  ],
},
  {
    id: '11',
    restaurantId: '2',
    name: 'Мраморная говядина со свежей зеленью',
    image: 'https://i.imgur.com/2L4kbPy.jpg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.SEASON],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 745,
        weight: 150,
        ingredients: 'куриная грудка, зелень',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 999,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.SPORT, DietTag.HEALTHY_HEART],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '12',
    restaurantId: '2',
    name: 'Лосось под цитрусовым соусом',
    image: 'https://i.imgur.com/N6OedK8.jpg',
    isOnStartPage: true,
    menuSectionTags: [MenuSectionTag.RECOMMENDED],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 1300,
        weight: 400,
        ingredients: 'лосось из пруда ОРУДЬЕВО, цитрусовый соус',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 999,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.SPORT, DietTag.HEALTHY_HEART],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '13',
    restaurantId: '2',
    name: 'Сырники ванильные с ягодным соусом',
    image: 'https://i.imgur.com/VCGF1IQ.jpg',
    isOnStartPage: true,
    menuSectionTags: [MenuSectionTag.DESERTS],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 350,
        weight: 279,
        ingredients: 'Творог, ягоды, ваниль',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.VEGAN, DietTag.VEGETARIAN],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '14',
    restaurantId: '2',
    name: 'Ролл Филадельфия',
    image: 'https://i.imgur.com/ioUk2YV.jpg',
    isOnStartPage: true,
    menuSectionTags: [MenuSectionTag.SEASON],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 850,
        weight: 370,
        ingredients: 'Ролл Филадельфия',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.HEALTHY_STOMACH, DietTag.LESS_500],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '15',
    restaurantId: '2',
    name: 'Яичница с креветками',
    image: 'https://i.imgur.com/9aL6MeV.jpg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.RECOMMENDED, MenuSectionTag.SEASON],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 380,
        weight: 250,
        ingredients: 'яйцо, масло, креветки, зелень',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.VEGAN, DietTag.VEGETARIAN],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '16',
    restaurantId: '1',
    name: 'Лимонад',
    image: 'https://i.imgur.com/FUibMv0.jpg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.DRINKS],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 390,
        weight: 230,
        ingredients: 'лимонад',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.VEGAN, DietTag.VEGETARIAN],
        restrictedAllergyTags: [],
      },
    ],
  },
]


export const mockFood3: FoodPositionInOrderInfo[] = [
  {
    id: '1',
    restaurantId: '1',
    name: 'Картофель фри',
    image: 'https://i.imgur.com/6dtZI35.jpg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.SALADS, MenuSectionTag.RECOMMENDED],
    selectedVariationId: '1',
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 700,
        weight: 150,
        ingredients: 'картофель фри, трюфельное масло',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.HEALTHY_STOMACH, DietTag.DIABETIC],
        restrictedAllergyTags: [],
      },
      {
        variationId: '2',
        variationName: 'С сыром',
        variationType: VariationType.CUSTOM,
        price: 800,
        weight: 170,
        ingredients: 'картофель фри, трюфельное масло, сыр',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 222,
        fats: 333,
        carbohydrates: 444,
        calories: 555,
        allowedDietsTags: [DietTag.HEALTHY_HEART, DietTag.VEGAN],
        restrictedAllergyTags: [],
      }
    ],
  },
  {
    id: '2',
    restaurantId: '1',
    name: 'Авторский салат с авокадо и гранатом',
    image: 'https://i.imgur.com/RWer6Wh.jpg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.SALADS, MenuSectionTag.SEASON],
    selectedVariationId: '1',
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 600,
        weight: 150,
        ingredients: 'салатный лист, авокадо, гранат, гренки, петрушка, лук, капуста',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.VEGAN, DietTag.VEGETARIAN],
        restrictedAllergyTags: [],
      },
    ],

  },
  {
    id: '3',
    restaurantId: '1',
    name: 'Тыквенный суп-пюре',
    image: 'https://i.imgur.com/nLjUw2s.jpg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.SALADS, MenuSectionTag.SEASON],
    selectedVariationId: '1',
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 444,
        weight: 150,
        ingredients: 'суп',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.SPORT, DietTag.HEALTHY_STOMACH],
        restrictedAllergyTags: [],
      },
      {
        variationId: '2',
        variationName: 'С семечками',
        variationType: VariationType.CUSTOM,
        price: 586,
        weight: 170,
        ingredients: 'суп, семечки',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 222,
        fats: 333,
        carbohydrates: 444,
        calories: 555,
        allowedDietsTags: [DietTag.DIABETIC, DietTag.LESS_PROTEIN],
        restrictedAllergyTags: [],
      }
    ],
  },

]