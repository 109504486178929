// interface VerCMSProps {
  // isOpen: boolean;
  // onClose: () => void;
//   className?: string;
// }
const VerCMS = () => {
  return (
    <div className="desktop-subtext text-tetriary version-cms">
        YourMeal CMS 1.0.0
    </div>
  );
};

export default VerCMS;
